

















import { Component } from "vue-property-decorator";
import { Routes } from "@/types/core";
import { mixins } from "vue-class-component";
import userRolesMixin from "@/mixins/userRoles";
import { BaseView } from "@/core/UI/Views/BaseView";
import EmployeesList from "@/components/widgets/employees/EmployeesList.vue";

@Component({
  components: { EmployeesList },
})
export default class EmployeesView extends mixins(userRolesMixin, BaseView) {
  protected readonly Routes = Routes;

  public created(): void {
    if (!this.VIEW_USERS_LIST) this.$router.push({ name: Routes.noRights });
  }
}
