





























































































































































































































































import { Component, Watch } from "vue-property-decorator";
import { DocumentNode } from "graphql";
import { EmployeesShortDataList as Employees } from "@/graphql/queries/User.graphql";

import Table from "@/components/parts/tables/Table.vue";
import ListView from "@/core/UI/Views/ListView";
import { Routes } from "@/types/core";
import {
  EmployeesShortDataListQueryVariables,
  InputUsersEnum,
  QueryEmployeesOrderByColumn,
  SortOrder,
  SqlOperator,
  User,
} from "@/generated/graphql";
import { Debounce } from "vue-debounce-decorator";
import { TableHeader } from "@/components/parts/tables/Settings.vue";

@Component({
  components: { Table },
})
export default class EmployeesList extends ListView<User> {
  protected readonly fetchListQuery: DocumentNode = Employees;
  protected readonly fetchKey = "employees";

  protected readonly Routes = Routes;

  protected filter: Record<string, string> = {
    id: "",
    fullname: "",
    specialties: "",
    positions: "",
    emails: "",
    phones: "",
    extensionNumber: "",
    roles: "",
    city: "",
  };

  protected searchValue = "";

  protected readonly headers: TableHeader[] = [
    {
      value: "id",
      text: "ID",
      width: 100,
      hidden: false,
      sortable: false,
    },
    {
      value: "fullname",
      text: "ФИО",
      hidden: false,
      sortable: false,
    },
    {
      value: "positions",
      text: "Должность",
      hidden: false,
      sortable: false,
    },
    {
      value: "specialties",
      text: "Специальность",
      hidden: true,
      sortable: false,
    },
    {
      value: "extensionNumber",
      text: "Внутренний номер",
      hidden: true,
      sortable: false,
    },
    {
      value: "roles",
      text: "Роль",
      hidden: true,
      sortable: false,
    },
    {
      value: "city",
      text: "Город",
      hidden: true,
      sortable: false,
    },
    {
      value: "emails",
      text: "E-Mail",
      hidden: false,
      sortable: false,
    },
    {
      value: "phones",
      text: "Телефон",
      hidden: false,
      sortable: false,
    },
    {
      value: "_actions",
      text: "Действия",
      hidden: false,
      sortable: false,
    },
  ];

  protected get fetchListVariables(): EmployeesShortDataListQueryVariables {
    const orderBy: EmployeesShortDataListQueryVariables["orderBy"] = [
      { column: QueryEmployeesOrderByColumn.Id, order: SortOrder.Desc },
    ];

    const where: EmployeesShortDataListQueryVariables["where"] = { AND: [] };
    if (this.searchValue.length) {
      where.AND?.push({
        OR: [
          {
            column: InputUsersEnum.FirstName,
            operator: SqlOperator.Ilike,
            value: `%${this.searchValue}%`,
          },
          {
            column: InputUsersEnum.FamilyName,
            operator: SqlOperator.Ilike,
            value: `%${this.searchValue}%`,
          },
          {
            column: InputUsersEnum.SecondName,
            operator: SqlOperator.Ilike,
            value: `%${this.searchValue}%`,
          },
        ],
      });
    }

    return { where, orderBy };
  }

  protected visibleHeadersList: string[] = [];
  protected get filteredEmployees(): User[] {
    const filter = this.filter;
    const conditions: CallableFunction[] = [];

    if (filter.id) conditions.push(this.filterId);
    if (filter.fullname) conditions.push(this.filterFullname);
    if (filter.role) conditions.push(this.filterRole);
    if (filter.city) conditions.push(this.filterCity);
    if (filter.extensionNumber) conditions.push(this.filterExtensionNumber);
    if (filter.specialties) conditions.push(this.filterSpecialties);
    if (filter.positions) conditions.push(this.filterPositions);
    if (filter.emails) conditions.push(this.filterEmails);
    if (filter.phones) conditions.push(this.filterPhones);

    if (conditions.length > 0) {
      return (
        this.list?.data?.filter((item) =>
          conditions.every((cond) => cond(item))
        ) || []
      );
    } else return this.list?.data || [];
  }

  protected get exportResolvers(): Record<string, CallableFunction> {
    return {
      fullname: (item: User) =>
        [item.family_name, item.first_name, item.second_name].join(" "),
      specialties: (item: User) =>
        item.employeeProfile?.specialties
          .map((s) => s.name)
          .join(", ")
          .toLowerCase() || "",
      positions: (item: User) =>
        item.employeeProfile?.positions
          .map((p) => p.name)
          .join(", ")
          .toLowerCase() || "",
      extensionNumber: (item: User) =>
        item?.employeeProfile?.extension_number || "",
      roles: (item: User) => item.roles?.map((r) => r.name).join(", "),
      city: (item: User) => (item.city ? item.city.name : "-"),
      phones: (item: User) => item.phones?.map((e) => e.value).join(", ") || "",
      emails: (item: User) => item.emails?.map((e) => e.value).join(", ") || "",
    };
  }

  @Watch("searchValue")
  @Debounce(500)
  private search(): void {
    this.refetch();
  }

  private filterId(item: User): boolean {
    if (!this.visibleHeadersList.includes("id")) return true;
    return +item.id === +this.filter.id;
  }
  private filterCity(item: User): boolean {
    if (!this.visibleHeadersList.includes("city")) return true;
    return !!item.city?.name
      .toLowerCase()
      .includes(this.filter.city.toLowerCase());
  }
  private filterFullname(item: User): boolean {
    if (!this.visibleHeadersList.includes("fullname")) return true;
    return [item.family_name, item.first_name, item.second_name]
      .join(" ")
      .toLowerCase()
      .includes(this.filter.fullname.toLowerCase());
  }
  private filterRole(item: User): boolean {
    if (!this.visibleHeadersList.includes("roles")) return true;
    return !!item.roles
      ?.map((r) => r.name.toLowerCase())
      .join(" ")
      .includes(this.filter.role.toLowerCase());
  }
  private filterSpecialties(item: User): boolean {
    if (!this.visibleHeadersList.includes("specialties")) return true;
    return !!item.employeeProfile?.specialties
      .map((s) => s.name.toLowerCase())
      .join(" ")
      .includes(this.filter.specialties.toLowerCase());
  }
  private filterPositions(item: User): boolean {
    if (!this.visibleHeadersList.includes("positions")) return true;
    return !!item.employeeProfile?.positions
      .map((p) => p.name.toLowerCase())
      .join(" ")
      .includes(this.filter.positions.toLowerCase());
  }
  private filterPhones(item: User): boolean {
    if (!this.visibleHeadersList.includes("phones")) return true;
    return !!item.phones
      ?.map((p) => p.value)
      .join(" ")
      .includes(this.filter.phones);
  }

  private filterEmails(item: User): boolean {
    if (!this.visibleHeadersList.includes("emails")) return true;
    return !!item.emails
      ?.map((e) => e.value)
      .join(" ")
      .toLowerCase()
      .includes(this.filter.emails.toLowerCase());
  }

  private filterExtensionNumber(item: User): boolean {
    if (!this.visibleHeadersList.includes("extensionNumber")) return true;
    console.log(
      item.employeeProfile?.extension_number,
      this.filter.extensionNumber
    );
    const num = "" + item?.employeeProfile?.extension_number || "";
    return num.includes(this.filter.extensionNumber);
  }
}
